/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React, { useEffect, useState, lazy, Suspense } from "react";
import { format, parseISO } from "date-fns";
import axios from "axios";
import "@fontsource/poppins";
import "./App.css";
//import { ProductsHeader } from "genericproductsheader";
//import CustomFooter from "./components/FooterComponent/index";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import { PressReleaseComponent } from "press-release";
import BannerCarousel from './components/BannerCarousel';
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
const LogoSection = lazy(() => import("./components/Logo Section/Logo"));
const Navbar = lazy(() => import("../src/components/HeaderNav"));

const Menulist = lazy(() => import("./components/Menubar/ProductsHeader.js"))
const Header = lazy(() => import("./components/Header"));
const Highlights = lazy(() => import("./components/Highlights"));
const Footer = lazy(() => import("./components/FooterNew/index.js"));
const LoanCalculator = lazy(() => import("./components/LoanCalculator"));
// const News = lazy(()=>import("./components/InTheNews/js/News"));
const VideoCarousel = lazy(() => import("./components/VideoCarousel/index.js"));

function App() {
  const imgCDNURL = process.env.REACT_APP_MORTGAGES4VETERANS_CDN_URL;

  const [themeId, setThemeId] = useState("mortgage4veterans-pricing");
  const [configJSON, setConfigJSON] = useState("");
  const [va15, setVA15] = useState("");
  const [va30, setVA30] = useState("");
  const [pricingAsOfDate, setPricingAsOfDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseIpify = await axios.get("https://api.ipify.org", { timeout: 5000 });
        const userIP = responseIpify.data;

        if (userIP) {
          const responseMaxmind = await axios.post(
            process.env.REACT_APP_MAXMIND,
            { ip: userIP },
            { headers: { "Content-Type": "application/json" }, timeout: 5000 }
          );

          const maxmindData = responseMaxmind.data;
          const redirectionUrl = process.env.REACT_APP_REDIRECTION_URL;
          if (maxmindData !== "IP Not Available" && maxmindData !== "Invalid Country") {
            const userZipcode = maxmindData.zipcode;

            if (userZipcode) {
              const responseZipcode = await axios.get(
                process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                { headers: { "Content-Type": "application/json" }, timeout: 5000 }
              );

              const userState = responseZipcode.data.state;
              const basename = process.env.REACT_APP_BASENAME;

              if (basename === "/" && userState === "NY") {
                window.location.href = redirectionUrl;  
              }
            } else {
              console.error("Location Error: Zipcode not found");
            }
          } else {
            console.error("Location Error: MaxMind data unavailable");
          }
        } else {
          console.error("Location Error: IP not found");
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    fetchData();
  }, []);

  async function locationError(error) {
    // Get the default price scenario
    await getPrice();
  }

  /**
   * @description Get the pricing config file details
   * 
   */
  const getConfigData = async () => {
    try {
      // Get the mortgage4veterans-config.json file from S3 bucket
      const configData = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/mortgage4veterans-config.json`
      );

      //console.log(JSON.stringify(configData.data[0]));
      if (configData.data[1].VA_30_YR_FIXED) {
        setConfigJSON(configData.data[1].VA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load mortgage4veterans-config.json file from S3 bucket: ", error);
    }
  };

  /**
   * @description Get the static pricing data from s3.
   * 
   */
  const getPrice = async (userStateCode) => {
    try {
      // Get the static data from s3 for the filtered state else return the default data
      // Get the state list
      const stateList = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/state.json`
      );
      // Filter the state which has the county and zipcode
      const propertyStateData = stateList.data.find(
        (d) => d.state.code === userStateCode
      );
      //console.log("propertyStateData: " + JSON.stringify(propertyStateData));
      let response;
      if (propertyStateData) {
        const county = propertyStateData.state.county.code;
        const zipcode = propertyStateData.state.county.zipcode;
        // console.log("county: " + county);
        // console.log("zipcode: " + zipcode);
        // console.log("themeId: " + themeId);
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT
          }/${themeId}/${userStateCode.toLowerCase()}/${county}/${zipcode}/products.json`
        ).catch((error) => {
          console.error("Unable to pull pricing for user state: ", error);
          locationError();
        });
      } else {
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT}/${themeId}/default.json`
        );
      }
      // console.log("response: " + JSON.stringify(response.data));
      const parseValue = parseISO(response.data.createdAt);
      const dateTime = format(parseValue, "MM/dd/yyyy hh:mm a");
      setPricingAsOfDate(dateTime);

      if (response.data.VA_15_YR_FIXED) {
        setVA15(response.data.VA_15_YR_FIXED);
      }

      if (response.data.VA_30_YR_FIXED) {
        setVA30(response.data.VA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load todays rate: ", error);
    }
  };

  useEffect(() => {
    let userIP = "";
    let userZipcode = "";
    let userState = "";

    const fetchData = async () => {
      await axios
        .get("https://api.ipify.org", { timeout: 5000 })
        .then((response) => {
          userIP = response.data;
          getConfigData();
          //console.log("userIP: " + userIP);
          if (userIP) {
            axios
              .post(
                process.env.REACT_APP_MAXMIND,
                { ip: userIP },
                {
                  headers: { "Content-Type": "application/json" },
                },
                { timeout: 5000 }
              )
              .then((response) => {
                //console.log("response.data: " + JSON.stringify(response.data));
                if (
                  response.data !== "IP Not Available" &&
                  response.data !== "Invalid Country"
                ) {
                  userZipcode = response.data.zipcode;
                  //console.log("userZipcode: " + userZipcode);
                  if (userZipcode) {
                    axios
                      .get(
                        process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                        {
                          headers: { "Content-Type": "application/json" },
                        },
                        { timeout: 5000 }
                      )
                      .then((response) => {
                        userState = response.data.state;
                        //console.log("userState: " + userState);
                        // get the price scenario
                        getPrice(userState);
                      })
                      .catch((error) => {
                        console.error("Get ZIPCODE Error: ", error);
                        locationError();
                      });
                  } else {
                    locationError();
                  }
                } else {
                  locationError();
                }
              })
              .catch((error) => {
                console.error("Post MAXMIND Error: ", error);
                locationError(error);
              });
          }
        })
        .catch((error) => {
          console.error("Get ipify Error: ", error);
          locationError(error);
        });
    };

    fetchData();
  }, []);
  const appVersion = appversion.version;
  return (
    <>
    <CacheBuster
          currentVersion={appVersion}
          isEnabled={true}
          isVerboseMode={false}
          metaFileDirectory={"."}
        >
      <div className="bodyContainer mb-5">
        <img
          src={imgCDNURL + "6dots-group.png"}
          className="dots-group img-fluid"
          alt="6dots-group"
        />
        <img
          src={imgCDNURL + "va-desktop.webp"}
          className="desktop-bg img-fluid"
          alt="desktop-bg"
        />
        <img
          src={imgCDNURL + "va-mobile.webp"}
          className="mobile-bg img-fluid"
          alt="mobile-bg"
        />
        {/* <div className="wsj">
          <div>
          <img
                src={imgCDNURL + "wsj.webp"}
                className="wsj-business"
                alt="WSJ Business Article"
          />
          </div>
          <a href='https://partners.wsj.com/sun-west/an-underwriter-at-your-fingertips/'>
          <button className="btn wsj-btn">
            Click here for complete article
          </button>
          </a>
        </div> */}
        <Suspense fallback={<div />}>
          <Navbar />
        </Suspense>
        <Suspense fallback={<div />}>
          <Header configJSON={configJSON} va15={va15} va30={va30} pricingAsOfDate={pricingAsOfDate} />
        </Suspense>
        <Suspense fallback={<div />}>
          <LogoSection fetchpriority="low" />
        </Suspense>
      </div>
      <Suspense fallback={<div />}>
        <Highlights />
      </Suspense>
      <Suspense fallback={<div />}>
        <div className="news pt-4">
          <PressReleaseComponent />
        </div>
      </Suspense>
      <Suspense fallback={<div />}>
        <BannerCarousel title="Video Testimonials" />
      </Suspense>


      <Suspense fallback={<div />}>
        {va30 ? <LoanCalculator offerRate={va30.rate} offerTerm={360} /> : ""}
      </Suspense>
      {/* <div>
              <iframe
                id="iframeContactModal"
                src="https://swmc-landingpages.s3.amazonaws.com/development-swmc-contactusmodule/index.html"
                allow="geolocation"
                frameborder="0"
                width="100%"
                scrolling="no"
              ></iframe>
            </div> */}
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
      {/* <CustomFooter /> */}
      {/* <iframe src="https://swmc-landingpages.s3.amazonaws.com/development-swmc-contactusmodule/index.html"></iframe> */}
      </CacheBuster>
    </>
  );
}

export default App;
